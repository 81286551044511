<template>
  <div class="container">
    <div>
      <div class="search-container">
        <el-form
            :inline="true"
            :model="formInline"
            @submit.native.prevent
            class="demo-form-inline"
        >
          <el-form-item label="订单号">
            <el-input v-model="formInline.order_no" placeholder="请输入订单号"></el-input>
          </el-form-item>
          <el-form-item label="第三方订单号">
            <el-input v-model="formInline.trade_no" placeholder="请输入第三方订单号"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="formInline.mobile" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="支付状态">
            <el-select v-model="formInline.status" placeholder="请选择支付状态">
              <el-option
                  v-for="item of payStatusSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单来源">
            <el-select v-model="formInline.order_source" placeholder="请选择订单来源">
              <el-option
                  v-for="item of orderSourceSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="formInline.order_type" placeholder="请选择订单类型">
              <el-option
                  v-for="item of orderTypeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="formInline.call_status" placeholder="请选择订单状态">
              <el-option
                  v-for="item of callStatusSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
                v-model="date"
                value-format="yyyy-MM-dd"
                type="daterange"
                @change="dateHandle"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <!--<el-button v-indentify="'consult:list:view:addOrder'" type="primary" icon="el-icon-plus" @click="handle('add')">添加订单</el-button>-->
          </el-form-item>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              width="180"
              label="订单信息">
            <template slot-scope="scope">
              <div>订单ID：{{ scope.row.order_id }}</div>
              <div>订单金额：{{ scope.row.price }} 元</div>
              <div>手机号：{{ scope.row.mobile }}</div>
            </template>
          </el-table-column>
          <el-table-column
              width="320"
              label="订单编号">
            <template slot-scope="scope">
              <div>短编号：{{ scope.row.order_no }}</div>
              <div>长编号：{{ scope.row.trade_no }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="产品信息">
            <template slot-scope="scope">
              <div>ID：{{ scope.row.service_item_id }}</div>
              <div>名称：{{ scope.row.service_item_name }}</div>
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              label="平台信息">
            <template slot-scope="scope">
              <div>标识：{{ scope.row.platform }}</div>
              <div>名称：{{ scope.row.platform_name }}</div>
            </template>
          </el-table-column>
          <el-table-column
              width="250"
              label="服务时间">
            <template slot-scope="scope">
              <div>开始：{{ scope.row.service_start_time | dateFormat }}</div>
              <div>结束：{{ scope.row.service_end_time | dateFormat }}</div>
              <div>时长：{{ scope.row.minute }}分钟</div>
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              align="center"
              label="订单标签">
            <template slot-scope="scope">
              <div>生成：
                <StatusTag effect="plain" size="mini" :state="scope.row.order_type" :data="orderTypeEnum"/>
              </div>
              <div>来源：
                <StatusTag effect="plain" size="mini" :state="scope.row.order_source" :data="orderSourceEnum"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              label="支付状态">
            <template slot-scope="scope">
              <div>
                <StatusTag :state="scope.row.status" :data="payStatusEnum"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="时间"
              width="280">
            <template slot-scope="scope">
              <div>创建时间：{{ scope.row.create_time | dateFormat }}</div>
              <div v-if="scope.row.pay_time" style="color:#ec8a3c;">付款时间：{{ scope.row.pay_time | dateFormat }}</div>
            </template>
          </el-table-column>
          <el-table-column
              width="180"
              label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.status !== 2">
                <!--<el-button v-indentify="'order:list:view:serviceRecordDetail'" type="primary" size="small" @click="handle('edit', scope.row.order_id)">设置服务</el-button>-->
                <!--<el-button v-indentify="'consult:list:view:paySmsPlan'" type="primary" size="small" @click="handle('edit', scope.row.order_id)">短信</el-button>-->
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <PcPaginationView @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :total="total"
                        :page-size="page_size"/>
    </div>
    <OrderDrawer
        :visible.sync="showDrawer" :title="handleFlag === 'add' ? '添加订单' : '编辑订单'" :drawerForm="drawerForm"
        :flag="handleFlag" @cancel="cancelDrawer" @confirm="confirmDrawer">
      <el-form-item label="服务时间">
        <el-date-picker
            style="width: 100%"
            v-model="drawerFormDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :clearable="false"
            @change="drawerFormDateHandle"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
    </OrderDrawer>
  </div>
</template>

<script>
import Mixin from '@/utils/Mixin'
import StatusTag from '@/components/pc/common/StatusTag'
import { mapState, mapActions } from 'vuex'
import PcPaginationView from '@/components/Pagination/pc'
import { orderListApi, serviceRecordDetailApi } from '@/api/order'
import OrderDrawer from '@/components/pc/OrderDrawer'

export default {
  name: 'PcOrderList',
  mixins: [Mixin],
  components: {
    StatusTag,
    PcPaginationView,
    OrderDrawer
  },
  computed: {
    ...mapState('selectOptions', ['payStatusSelect', 'orderSourceSelect', 'orderTypeSelect', 'callStatusSelect']),
    ...mapState('statusTagEnum', ['payStatusEnum', 'orderTypeEnum', 'orderSourceEnum', 'callStatusEnum'])
  },
  data () {
    return {
      formInline: {
        order_no: '',
        trade_no: '',
        mobile: '',
        status: '',
        order_source: '',
        order_type: '',
        call_status: '',
        start_time: '',
        end_time: ''
      },
      drawerForm: {
        order_id: '',
        mobile: '',
        service_mobile: '',
        service_item_id: '',
        service_start_time: '',
        service_end_time: ''
      },
      date: [],
      drawerFormDate: [],
      page: 1,
      page_size: 10,
      total: 0,
      tableData: [],
      handleFlag: '',
      showDrawer: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapActions('selectOptions', ['getServiceItem']),
    async getList () {
      const data = await orderListApi({
        page: this.page,
        page_size: this.page_size,
        ...this.formInline
      })
      if (data.code === 1) {
        this.tableData = data.data.data
        this.total = data.data.total
      }
    },
    async getServiceRecordDetail () {
      const { order_id } = this.drawerForm
      const data = await serviceRecordDetailApi({ order_id })
      if (data.code === 1) {
        if (data.data === null) return
        const detail = data.data
        const service_start_time = this.updateDrawerForm(detail.start_time)
        const service_end_time = this.updateDrawerForm(detail.end_time)
        this.drawerForm = {
          order_id,
          mobile: detail.client_mobile,
          service_mobile: detail.service_mobile,
          service_item_id: detail.id,
          service_start_time,
          service_end_time
        }
        this.drawerFormDate = [service_start_time, service_end_time]
      }
    },
    async handle (flag, order_id = '') {
      if (flag === 'edit') {
        this.drawerForm.order_id = order_id
        await this.getServiceRecordDetail()
      }
      await this.getServiceItem()
      this.showDrawer = true
      this.handleFlag = flag
    },
    cancelDrawer () {
      const { drawerForm } = this.$data
      for (const drawerFormKey in drawerForm) {
        drawerForm[drawerFormKey] = ''
      }
      this.drawerForm = drawerForm
      this.drawerFormDate = []
    },
    confirmDrawer () {
      this.getList()
    },
    // 查询
    async onSearch () {
      this.page = 1
      await this.getList()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    /**
     * 时间区间change
     * @param date
     */
    dateHandle (date) {
      if (date) {
        this.formInline.start_time = date[0]
        this.formInline.end_time = date[1]
      } else {
        this.formInline.start_time = ''
        this.formInline.end_time = ''
      }
    },
    // 时间戳转时间
    updateDrawerForm (date) {
      return this.$dateFormat(date, 'YYYY-MM-DD HH:mm:ss')
    },
    /**
     * 时间区间change
     * @param date
     */
    drawerFormDateHandle (date) {
      if (date) {
        this.drawerForm.service_start_time = date[0]
        this.drawerForm.service_end_time = date[1]
      } else {
        this.drawerForm.service_start_time = ''
        this.drawerForm.service_end_time = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.chat-content {
  max-width: 300px;
  word-break: break-all;
  font-size: 12px;
  margin: 0 10px;
}
</style>
